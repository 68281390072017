//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import {HAS_TOKEN} from '~/store/auth/types.auth'
import {SET_BC} from '~/store/bc/types.bc'

export default {
  components: {
    Breadcrumbs,
    Gallery: () => import('~/components/UI/Gallery/Gallery.vue'),
    ProductNavigation: () =>
      import('~/components/pages/Product/components/ProductNavigation.vue'),
    ProductDescriptions: () =>
      import('~/components/pages/Product/ProductDescriptions.vue'),
    ProductsSlider: () =>
      import('~/components/modules/Sliders/ProductsSlider.vue'),
    ReviewsModule: () =>
      import('~/components/modules/Reviews/ReviewsModule.vue'),
    ProductItemCard: () =>
      import('~/components/modules/Cards/ProductItemCard.vue'),
  },
  data() {
    return {
      products: [],
      product: {},
      similar: [],
      navigation: [],
      itemInfo: {},
      title: this.$t('similarSets'),
    }
  },
  fetch() {
    return this.fetchProduct()
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    isAuth() {
      return this.$store.getters[`auth/${HAS_TOKEN}`]
    },
  },

  methods: {
    onClick(value) {
      const {id, data} = value
      const keys = ['labels', 'status', 'name', 'price_type', 'slug']
      const slide = this.items.find((item) => item.id === id)
      for (const key of keys) {
        slide[key] = data[key]
      }
      const variations = Object.keys(data.variations).filter(
        (key) => key !== 'primary',
      )
      slide.variations = data.variations(
        data.variations.primary ? data.variations.primary : variations[0],
      ).map((variation) => ({
        ...variation,
        file: variation.files[0]?.preview_url ? variation.files[0].preview_url : '',
      }))
    },
    fetchProduct() {
      const slug = this.$route.params.id
      return this.$request.$get(`/set/${slug}`).then(({data}) => {
        const {similar, blocks, breadcrumbs, products, name, files} = data
        // console.log(data.slug, 'name')
        this.itemInfo = {
          item_name: name,
          file_preview_url: files[0] && files[0].url,
          slug: data.slug,
        }
        // console.log(products, 'products')
        this.products = products.map(item => {
          return {
            ...item,
            variations: item.variations.map((variation) => {
              return {
                ...variation,
                file: variation.files[0] ? variation.files[0].preview_url : '',
              }
            }),
          }
        })
        this.similar = similar.map((s, i) => {
          const variationsKeys = Object.keys(s.variations).filter(
            (key) => key !== 'primary',
          )
          const variations =
            s.variations[
              s.variations.primary ? s.variations.primary : variationsKeys[0]
            ]
          return {
            ...s,
            id: i,
            variations:
              variations && variations[0]
                ? variations.map((variation) => ({
                  ...variation,
                  file: variation.files[0]?.preview_url || '',
                }))
                : [],
          }
        })
        const payloadArray = [{name: data.name, slug: data.slug}]
        if (breadcrumbs.length && breadcrumbs[0]) {
          payloadArray.unshift( { name: breadcrumbs[0].name, slug: `/product/catalog/${breadcrumbs[0].slug}` })
        }
        this.$store.commit(`bc/${SET_BC}`, payloadArray)
        // console.log(data.files, 'data.files')
        this.product = {
          ...data,
          files: data.files.map((file) => file.url),
          variations: data.variations,
        }
        // console.log(this.product, 'product')
        const blocksNav = blocks.map((block) => {
          const to = block.name.split(' ')[0].toLowerCase()
          return {
            name: block.name,
            to,
          }
        })
        this.navigation = [
          {
            name: this.$t('productsInSet'),
            to: 'products-set',
          },
          ...blocksNav,
          ...[
            {
              name: this.$t('specification'),
              to: 'specification',
            },
            {
              name: `${this.$t('reviews')}(${this.product.reviews_count})`,
              to: 'reviews',
            },
          ],
        ]
        if(!this.settings.reviews) {
          this.navigation = this.navigation.filter(item => item.to !== 'reviews')
        }
      })
      // console.log(this.products, 'this.products')
    },
    getProduct() {
      this.fetchProduct()
    },

    handleNavScroll(i) {
      // console.log(i)
      const element = document.getElementById(`section-${i}`)
      const top = element.offsetTop - 150
      window.scrollTo({
        behavior: 'smooth',
        top,
      })
    },
  },
}
